import { postMethodApiCall } from "src/Services/API"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
}

export const ADMIN_ADD_USER = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/register`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            return res
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}