import { Navigate, Outlet } from "react-router-dom";
import ChatbotIcon from "src/Layouts/chatbot";

const useAuth = () => {
    const user = { loggedIn: localStorage.getItem("token") !== null ? true : false }
    return user && user.loggedIn
}
const ProtectedRoute = () => {
    const isAuth = useAuth();

    return isAuth ? <>
     <ChatbotIcon />
    <Outlet />
    </> : <Navigate to='/login' />
}

export default ProtectedRoute;