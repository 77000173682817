import { ToastContainer } from "react-toastify"
import ChatroomLayout from "src/Layouts/chatbot/chatroom"


const ChatBot = () => {
    return (
        <>
            <ChatroomLayout />
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default ChatBot