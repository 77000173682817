import { useState } from "react";
import ChatroomLayout from "./chatroom";
import { useLocation } from "react-router-dom";
import chatIcon from 'src/Assets/Icons/Gemini_Generate.jpeg'
import { IconButton } from "@mui/material";

const ChatbotIcon = () => {
    const location = useLocation()
    const [open, setOpen] = useState(false)

    return (
        <div className=" fixed bottom-4 right-4 z-50">
            {(location?.pathname !== '/app/chatbot' ? <>
                {open ? <div className="flex flex-col w-80 h-[calc(80vh)]  sm:w-96 sm:h-112 bg-white border border-gray-300 shadow-lg rounded-lg overflow-hidden">
                    <ChatroomLayout setOpen={setOpen} />
                </div> : <IconButton className='bg-white bg-gray-50/10 animate-pulse' size='small' onClick={() => setOpen(true)}>
                    <img className="w-12 h-12 rounded-full" alt="chaticon" src={chatIcon} />
                </IconButton>}
            </> : '')
            }
        </div>
    )
}

export default ChatbotIcon;