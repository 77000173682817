import { ToastContainer } from "react-toastify"
import AdminUserLayout from "src/Layouts/AdminUser/userCreation"


const AdminUser = () => {
    return (
        <>
            <AdminUserLayout />
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default AdminUser