import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Chip } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { AlertError } from "src/Services";


const DownlaodBotton = ({ link , filename}) => {
    const [loader, setLoader] = useState(false)

    const handleTestAPI = () => {
        if(loader){
            AlertError('Please Wait file download is in process!')
            return
        }
        setLoader(true)

        const payload = {
            "link" : link,
            "file_name": filename
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/odata/download/image`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': '{{ csrf_token }}'
            },
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = link?.split('/').pop(); 
                document.body.appendChild(a);
                a.click();
                
                const debounceTimer = setTimeout(() => {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    setLoader(false)
                }, 0)
        
                return () => {
                    clearTimeout(debounceTimer)
                }
            })
            .catch(error => {
                setLoader(false)
                AlertError('There was an error downloading the file!');
            });
    }

    return (
        <Chip
            label={loader ? 'Processing...' : 'Download'}
            onClick={handleTestAPI}
            icon={<CloudDownloadIcon />}
            variant="outlined"
        />
    )
}

export default DownlaodBotton;