import { ACTION_LOGIN_USER, ACTION_CHATBOT_MESSAGES, ACTION_CHAT_API_CALL_TRUE } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    logedInUser: null,
    chatbotMessages: [],
    chatapiCall: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_LOGIN_USER:
            return {
                ...state,
                logedInUser: action.data
            }
        case ACTION_CHATBOT_MESSAGES:
            return {
                ...state,
                chatbotMessages: [...state?.chatbotMessages, action.data],
            }
        case ACTION_CHAT_API_CALL_TRUE:
            return {
                ...state,
                chatapiCall: action.data
            }

        default:
            return state
    }
}

export default userReducer