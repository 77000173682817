import OrdersTable from './OrderTable'
import TotalCart from 'src/Components/TotalCart';
import ReOrder from 'src/Components/ReOrder';
import { GET_ORDERS } from 'src/Services/Redux/Actions/orders'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import Button from '@mui/material/Button'

// 
import DaysSelect from './daysSelect';
import BasicDetails from './BasicDetails';
import SearchByColumn from './searchByColumn';
import FilesListing from './filesListing';
import Notes from './notes'
import FilterModal from './filterModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OrderLayout = () => {
    const dispatch = useDispatch()
    const [tabis, setTabis] = useState('detail');
    const [day, setDay] = useState(120)
    const [rows, setRows] = useState(10)
    const [page, setPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0)
    const [selectedRowDetails, setSelectedRowDetails] = useState(null);
    const [searchStr, setSearchStr] = useState('')
    const [filterField, setFilterFiled] = useState('')
    const [imageData, setImageData] = useState(null)
    const [image_UniqueID, setImage_UniqueID] = useState(null)
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))

    const orders = useSelector(state => state?.ordersReducer?.orders)

    const [apiPayload, setApiPayload] = useState({
        start_date: moment().subtract(4, 'M').format('MM-DD-YYYY'),
        end_date: moment(new Date()).format('MM-DD-YYYY'),
        pageno: 1,
        limit: 10,
        searchStr: "",
        searchColumn: "",
        totalRecords,
        email: userinfo?.email,
    })

    useEffect(() => {
        if (orders?.pagination?.totalRecords) {
            setTotalRecords(orders.pagination?.totalRecords)
        }
    }, [orders])

    const ui_to_db = {
        "Style": "itemnumber",
        "Sales Order #": "salesordernumber",
        "Center Shape": "centerstoneshape",
        "Ring Size": "productsizeid",
        "Metal": "metaldesc",
        "Customer PO #": "customerrequisitionnumber",
        // "Customer No.": "orderingcustomeraccountnumber"
    }

    const fetchOrders = async () => {
        const formData = {
            email: userinfo?.email,
            ...apiPayload,
            searchStr,
            searchColumn: ui_to_db[filterField]
        };
        await dispatch(GET_ORDERS(formData))
        setLoader(false)
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    const handledate = (name, value) => {
        if(value){
            setApiPayload({
                ...apiPayload,
                totalRecords: 0,
                [name]: moment(value).format('MM-DD-YYYY')
            })
        }
     
    }


    const handleDays = async (day) => {
        setLoader(true)
        setDay(day)
        let updatedpayload = {
            start_date: moment().subtract((day / 30), 'M').format('MM-DD-YYYY'),
            end_date: moment(new Date()).format('MM-DD-YYYY'),
            pageno: 1,
            limit: 10,
            searchStr,
            searchColumn: filterField,
            totalRecords:0,
            email: userinfo?.email,
        }
        setApiPayload(updatedpayload)
        await dispatch(GET_ORDERS(updatedpayload))
        setLoader(false)
    }

    const handlePagination = async (p) => {
        setPage(p)
        const data = {
            ...apiPayload,
            pageno: p || 1,
            totalRecords,
            email: userinfo?.email,
        };
        await dispatch(GET_ORDERS(data))
        setApiPayload(data)
    }

    const handleRows = async (r) => {
        setRows(r)
        setLoader(true)
        const data = {
            ...apiPayload,
            limit: r,
            totalRecords,
            email: userinfo?.email,
        };
        await dispatch(GET_ORDERS(data))
        setLoader(false)
        setApiPayload(data)
    }

    return (
        <div className='w-full'>
            <div className='w-full flex justify-between items-center my-2 pr-2 '>
                <h2 className='text-gray-800 font-[600] text-lg'>Orders</h2>
                <div className='flex justify-start items-center space-x-2'>
                    <ReOrder />
                    <TotalCart />
                </div>
            </div>
            <div className='my-2 w-full flex justify-between items-center '>
                {/* Below ui for large Screen */}
                <div className='hidden lg:block'>
                    <div id='filterTab' className='my-2 flex justify-start items-center space-x-2'>
                        <SearchByColumn
                            hooks={{
                                fetchOrders,
                                searchStr,
                                setSearchStr,
                                filterField,
                                setFilterFiled,
                                selectWidth: '160px'
                            }}
                        />
                        <DaysSelect
                            hooks={{ day, handleDays }}
                            id='select-last-days-filter'
                        />

                        <DatePicker
                            className='h-10 rounded-md px-2' selected={apiPayload?.start_date || ''}
                            name='start_date' onChange={(date) => handledate('start_date', date)}
                            // dateFormat="MM-DD-YYYY"
                        />
                        <DatePicker
                            className='h-10 rounded-md px-2' selected={apiPayload?.end_date || ''}
                            name='end_date' onChange={(date) => handledate('end_date', date)}
                            // dateFormat="MM-DD-YYYY"
                        />


                        <Button
                            onClick={fetchOrders}
                            className=' bg-gradient-to-tr  from-gray-700 to-gray-400 text-white py-1.5 px-3 font-[300] rounded-lg'>
                            Filter
                        </Button>
                    </div>
                </div>

                <div className='block lg:hidden'>
                    <FilterModal
                        hooks={{
                            fetchOrders,
                            searchStr,
                            setSearchStr,
                            filterField,
                            setFilterFiled,
                            apiPayload,
                            handleDays,
                            handledate,
                            day
                        }}
                    />
                </div>
            </div>
            <>
                <div className='w-full mb-2'>
                    <OrdersTable
                        hooks={{
                            loader,
                            handleRows,
                            handlePagination,
                            rows, page,
                            selectedRowDetails,
                            setSelectedRowDetails,
                            setImageData,
                            setImage_UniqueID,
                            totalRecords,
                            data: orders?.list
                        }}
                    />
                </div>
                {selectedRowDetails && <div className='bg-white rounded-md shadow min-h-64'>
                    <Tabs
                        value={tabis}
                        onChange={(e, data) => setTabis(data)} aria-label="lab API tabs example">
                        <Tab label="Order Details" value={'detail'} />
                        <Tab label="Files" value={'files'} />
                        <Tab label="Notes" value={'notes'} />
                    </Tabs>

                    {tabis === 'detail' && <>
                        {selectedRowDetails && <BasicDetails
                            rowData={selectedRowDetails} imageLink={imageData} image_uniqueID={image_UniqueID}/>}
                    </>}

                    {tabis === 'files' && <FilesListing />}

                    {tabis === 'notes' && <Notes />}

                </div>}
            </>
        </div>
    )
}

export default OrderLayout;